
        import * as module0 from '../images/android-chrome-192x192.png';import * as module1 from '../images/android-chrome-512x512.png';import * as module2 from '../images/apple-touch-icon.png';import * as module3 from '../images/board/eetu.jpg';import * as module4 from '../images/board/joni.jpg';import * as module5 from '../images/board/matias.jpg';import * as module6 from '../images/board/simo.jpg';import * as module7 from '../images/favicon-32.png';import * as module8 from '../images/favicon-64.png';import * as module9 from '../images/favicon.svg';import * as module10 from '../images/logo.svg';import * as module11 from '../images/mask-icon.svg';import * as module12 from '../images/social.png';import * as module13 from '../images/supporters/kisko_labs.svg';import * as module14 from '../images/white.svg'
        const modules = {
            "../images/android-chrome-192x192.png": module0,
            "../images/android-chrome-512x512.png": module1,
            "../images/apple-touch-icon.png": module2,
            "../images/board/eetu.jpg": module3,
            "../images/board/joni.jpg": module4,
            "../images/board/matias.jpg": module5,
            "../images/board/simo.jpg": module6,
            "../images/favicon-32.png": module7,
            "../images/favicon-64.png": module8,
            "../images/favicon.svg": module9,
            "../images/logo.svg": module10,
            "../images/mask-icon.svg": module11,
            "../images/social.png": module12,
            "../images/supporters/kisko_labs.svg": module13,
            "../images/white.svg": module14,
        };
        export default modules;
      