import { ready } from "./ready"

ready(() => {
  const menuBtn = document.querySelector(".hamburger-menu")
  const menuItems = document.querySelector("#menu-items")?.classList

  const iconContainer = document.querySelector(".icon-container")?.classList
  const firstLine = document.querySelector("#first-line")?.classList
  const secondLine = document.querySelector("#second-line ")?.classList
  const thirdLine = document.querySelector("#third-line")?.classList

  menuBtn?.addEventListener("click", function () {
    const menuExpanded = menuBtn?.getAttribute("aria-expanded")
    if (menuExpanded === "false") {
      menuBtn?.setAttribute("aria-expanded", "true")
      menuBtn?.setAttribute("aria-label", "Close Menu")
      menuItems?.remove("display-none")

      // icon animation
      iconContainer?.remove("flex")
      iconContainer?.add("relative")
      firstLine?.add("rotate-45", "absolute", "bottom-1/2")
      thirdLine?.add("display-none")
      secondLine?.add("!w-full", "-rotate-45", "absolute", "bottom-1/2")
    } else {
      menuBtn?.setAttribute("aria-expanded", "false")
      menuBtn?.setAttribute("aria-label", "Open Menu")
      menuItems?.add("display-none")

      // icon animation
      iconContainer?.add("flex")
      iconContainer?.remove("relative")
      firstLine?.remove("rotate-45", "absolute", "bottom-1/2")
      thirdLine?.remove("display-none")
      secondLine?.remove("!w-full", "-rotate-45", "absolute", "bottom-1/2")
    }
  })
})
